import { IconButton, InputAdornment, makeStyles, TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { BaseEmoji, emojiIndex } from "emoji-mart";
import React, { ChangeEvent, FocusEvent, MutableRefObject, useCallback, useMemo, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { deepmerge } from "../../../utils/objects";
import { parseEmoji, titleWithEmoji } from "../../../utils/strings";
import { EmojiPicker } from "../../EmojiPicker";
import { AutocompleteTextfield } from "../AutocompletTextField";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

const useStyles = makeStyles((theme) => ({
  autocompleteItem: {
    ...theme.typography.body1,
    whiteSpace: "nowrap",
  },
}));

export type EmojiTextFieldControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps;

export type EmojiTextFieldControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, EmojiTextFieldControlRenderProps>, "render"> & {
    required?: string | boolean;
    iconClassName?: string;
    defaultEmojiClassName?: string;
    setInputRef?: (el: HTMLInputElement) => void;
  };

export const EmojiTextFieldControl: IRhfControl<EmojiTextFieldControlProps> = ({
  rules,
  required,
  disabled,
  helperText,
  iconClassName,
  defaultEmojiClassName,
  setInputRef,
  ...rest
}) => {
  const merged: EmojiTextFieldControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
      }),
    [rules, required]
  );

  const [open, setOpen] = useState<boolean>(false);
  const emojiButton = useRef<HTMLButtonElement>(null);
  const classes = useStyles();

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: EmojiTextFieldControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const getEmoji = () => parseEmoji(value || "").emoji;
      const getTitle = () => parseEmoji(value || "").textWithoutEmoji;

      const handleEmojiSelect = (emoji: string) => {
        void onChange(titleWithEmoji(emoji, getTitle()));
      };

      const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        void onChange(titleWithEmoji(getEmoji(), e.target.value));
        void rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        void onBlur();
        void rest.onBlur?.(e);
      };

      const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;

        if (
          getEmoji() &&
          key === "Backspace" &&
          (e.target as HTMLInputElement).selectionStart === 0 &&
          (e.target as HTMLInputElement).selectionEnd === 0
        ) {
          void handleEmojiSelect("");
        }
      };

      return (
        <>
          <AutocompleteTextfield
            autoComplete="off"
            {...rest}
            disabled={disabled}
            inputRef={(el) => {
              setInputRef?.(el);
              inputRef(el);
            }}
            {...{ name, value: getTitle(), onChange: handleInputChange, onBlur: handleBlur }}
            error={!!fieldState.error}
            helperText={!!fieldState.error?.message ? fieldState.error?.message : helperText}
            onKeyDown={handleKeydown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    ref={emojiButton}
                    disabled={disabled}
                    onClick={() => setOpen(true)}
                    className={iconClassName}
                    style={{ color: getEmoji() ? "black" : undefined }}
                  >
                    {!getEmoji() && (
                      <InsertEmoticonIcon className={defaultEmojiClassName} style={{ cursor: "pointer" }} />
                    )}
                    {getEmoji()}
                  </IconButton>
                </InputAdornment>
              ),
              ...rest.InputProps,
            }}
            trigger={{
              ":": {
                dataProvider: (token) =>
                  (emojiIndex.search(token) || [])
                    .map((o: BaseEmoji) => ({
                      colons: o.colons,
                      native: o.native,
                    }))
                    .slice(0, 4),
                component: ({ entity: { native, colons } }) => (
                  <div className={classes.autocompleteItem}>{`${native} ${colons}`}</div>
                ),
                afterWhitespace: true,
                output: (item) => `${item.native}`,
              },
            }}
          />
          <EmojiPicker
            open={open}
            anchorElRef={emojiButton as MutableRefObject<HTMLElement>}
            onClose={() => setOpen(false)}
            onSelect={handleEmojiSelect}
          />
        </>
      );
    },
    [open, setOpen, setInputRef, helperText, classes.autocompleteItem, defaultEmojiClassName, iconClassName, disabled]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

EmojiTextFieldControl.isControl = true;
EmojiTextFieldControl.isController = true;
