import { Box, darken, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { differenceInDays, format, formatRelative } from "date-fns";
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState, VFC } from "react";
import { useTimer } from "use-timer";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { usePromise } from "../../hooks/usePromise";
import { useSubscription } from "../../hooks/useSubscription";
import { reclaim } from "../../reclaim-api";
import { Calendar } from "../../reclaim-api/Calendars";
// eslint-disable-next-line no-restricted-imports
import { Task as TaskDto } from "../../reclaim-api/client";
import { dtoToTask, Task, taskToDto } from "../../reclaim-api/Tasks";
import { DATE_TIME_DISPLAY_FORMAT } from "../../utils/dates";
import { MicroTaskForm } from "../forms/tasks/MicroTaskForm";
import { Link } from "../Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  schedulingText: {
    padding: theme.spacing(1),
  },
  taskName: {
    fontWeight: theme.typography.fontWeightMedium,
    wordBreak: "break-word",
  },
  alert: {
    alignItems: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
    },
  },
  alertMessage: {
    maxWidth: "88%",
    padding: theme.spacing(1, 0, 0),
  },
  alertAction: {
    "& a": {
      color: darken(theme.palette.success.dark, 0.5),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1),
    },
  },
}));

export type QuickAddTaskProps = {
  disabled?: boolean;
  closePopover?: MouseEventHandler<HTMLAnchorElement>;
};

export const QuickAddTask: VFC<QuickAddTaskProps> = ({ disabled = false, closePopover }) => {
  const classes = useStyles();

  const [stored, setStored] = useLocalStorage<Partial<TaskDto>>("micro.task", undefined, undefined, (val) => {
    const next =
      !!val &&
      Object.entries(val).reduce((acc, [k, v]) => {
        if (undefined !== v && null !== v) acc[k] = v;
        return acc;
      }, {});

    return !!next && Object.keys(next).length ? next : null;
  });

  // Setting the local storage on every change causes the global popper to re-render
  // which randomly causes rendering issues with the form (most notably the secondary
  // poppper that hosts the calendars). useRef is preferred over state because it will
  // not get stale on the dismount action below when it is not included as a dep.
  const tempStore = useRef<Partial<TaskDto> | null>(stored || null);

  // Set local storage when this component dismounts
  useEffect(() => {
    return () => {
      setStored(tempStore.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // do not include deps we only want this to fire once.

  const { data: primaryCalendar } = usePromise(reclaim.calendars.getPrimary, []);

  const data = useMemo<Partial<Task> | null>(() => {
    if (stored) {
      return dtoToTask(stored as TaskDto);
    } else {
      return null;
    }
  }, [stored]);

  const [lastCreatedTask, setLastCreatedTask] = useState<Partial<Task> | null>(null);
  const watchedTask = useSubscription(reclaim.tasks.watchId$$, [lastCreatedTask?.id, true], !!lastCreatedTask?.id);
  const watchedStartDate = useMemo<Date | null>(() => watchedTask?.instances?.[0]?.start || null, [watchedTask]);

  const {
    time,
    start: startTimer,
    reset: resetTimer,
  } = useTimer({
    endTime: 15,
  });

  const handleSave = useCallback(
    async (task: Partial<Task>) => {
      const response = await reclaim.tasks.create(task as Omit<Task, "id">);
      setLastCreatedTask(response);

      tempStore.current = null;
      setStored(null);
      resetTimer();
      startTimer();
    },
    [resetTimer, startTimer, setStored]
  );

  const handleChange = useCallback((value: Partial<Task>) => {
    tempStore.current = taskToDto(value);
  }, []);

  return (
    <Box className={classes.root}>
      {/* Scheduling */}
      {!!lastCreatedTask && (!watchedTask || !watchedTask.instances?.length) && time < 15 && (
        <Alert
          severity="success"
          action={<span className={classes.schedulingText}>Scheduling...</span>}
          classes={{ root: classes.alert, message: classes.alertMessage, action: classes.alertAction }}
        >
          <span className={classes.taskName}>{lastCreatedTask.title}</span> has been created.
        </Alert>
      )}

      {/* Not scheduled */}
      {!!lastCreatedTask && !!watchedTask && !watchedTask?.instances?.length && time >= 15 && (
        <Alert title="Your calendar is full" severity="info">
          <span className={classes.taskName}>{lastCreatedTask.title}</span> will be scheduled soon.{" "}
          <Link href={`/tasks/${lastCreatedTask.id}`}>View task</Link>
        </Alert>
      )}

      {/* Scheduled */}
      {!!lastCreatedTask && !!watchedStartDate && (
        <Alert
          severity="success"
          action={
            <Link
              onClick={closePopover}
              href={`/planner?date=${format(watchedStartDate, "yyyy-MM-dd")}&eventId=${
                watchedTask?.instances?.[0].eventId
              }`}
            >
              View on calendar
            </Link>
          }
          classes={{ root: classes.alert, message: classes.alertMessage, action: classes.alertAction }}
        >
          <span className={classes.taskName}>{watchedTask?.title}</span> is scheduled for{" "}
          {differenceInDays(watchedStartDate, new Date()) > 6
            ? format(watchedStartDate, DATE_TIME_DISPLAY_FORMAT)
            : formatRelative(watchedStartDate, new Date())}
          .
        </Alert>
      )}
      <MicroTaskForm
        key="micro"
        data={data}
        primaryCalendar={primaryCalendar as Calendar}
        onSave={handleSave}
        onChange={handleChange}
      />
    </Box>
  );
};
